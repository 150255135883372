<template>
  <icon :glyph="icon" :size="size" @click="$emit('click', $event)"></icon>
</template>
<script>
const iconMap = {
  Start: 'source',
  TransactionalStart: 'transactional',
  Delay: 'clock',
  Email: 'email',
  Sms: 'sms',
  Push: 'push',
  Decision: 'question-mark',
  MultiDecision: 'multidecision',
  Split: 'test-tube',
  End: 'flag',
  AdRetarget: 'user-add',
  MultipleUser: 'multiple-user',
  AdRetargetRemove: 'user-remove',
  Promotion: 'volume-high',
  PromotionAdd: 'volume-up',
  PromotionRemove: 'volume-down',
  PromotionCredit: 'volume-high',
  Inbox: 'channel-inbox',
  Spacer: 'spacer',
}

export default {
  props: ['actionType', 'size'],

  computed: {
    icon() {
      return iconMap[this.actionType] || 'exclamation-mark-circle'
    }
  }
}
</script>
